import { IoTDevice } from "../API";

/**
 * AWS サービスの料金
 * @property serviceName - AWS のサービス名
 * @property cost - 料金（米ドル）
 */
export type AWSCost = {
    serviceName: string;
    cost: number;
};

/**
 * 利用料金を計算する処理を定義します。
 */
export class CostController {
    /**
     * 為替レート（円/ドル）
     */
    readonly EXCHANGE_RATE: number = 1;

    /**
     * IoTCore 平均メッセージサイズ（KB）
     */
    readonly IOT_CORE_MESSAGE_SIZE: number = 128;

    /**
     * IoTCore 1 送信周期当たりの請求可能なメッセージ数
     * メッセージサイズ / 5 KB メッセージの増分 = 請求可能なメッセージ（小数切り上げ）
     */
    readonly IOT_CORE_BILLABLE_MESSAGES_PER_SEND_CYCLE: number = Math.ceil(
        this.IOT_CORE_MESSAGE_SIZE / 5,
    );

    /**
     * IoTCore 1 送信周期当たりのルール実行回数
     */
    readonly IOT_CORE_RULES_PER_SEND_CYCLE: number = 1;

    /**
     * IoTCore 1 ルール当りのアクション数
     * - Lambda: UpdateDatum
     * - Lambda: CreateOldDatum
     * - S3: ログギング
     */
    readonly IOT_CORE_ACTIONS_PER_RULE: number = 3;

    /**
     * Lambda 1 送信周期当たりの実行回数
     */
    readonly LAMBDA_INVOKES_PER_SEND_CYCLE: number = 2;

    /**
     * Lambda 実行時間（ミリ秒 -> 秒）
     */
    readonly LAMBDA_DURATION: number = 3000 * 0.001;

    /**
     * Lambda メモリーサイズ（MB -> GB）
     */
    readonly LAMBDA_MEMORY_SIZE: number = 1024 * 0.0009765625;

    /**
     * DynamoDB データ項目 1 点当たりの書き込み回数
     */
    readonly DYNAMO_DB_WRITES_PER_DATA_POINT = 3;

    /**
     * DynamoDB データ項目 1 点当たりの読み取り回数
     */
    readonly DYNAMO_DB_READS_PER_DATA_POINT = 5;

    /**
     * AppSync データ項目 1 点当たりの GraphQL 実行回数
     */
    readonly APP_SYNC_GQL_INVOKES_PER_DATA_POINT = 4;

    /**
     * 1 PV 当りの各 AWS サービスの利用料金
     */
    readonly AWS_COSTS_PER_VIEW: readonly AWSCost[] = [
        {
            serviceName: "DynamoDB",
            cost: 0.0002650540903010953,
        },
        {
            serviceName: "Amplify",
            cost: 0.000003850970276437163,
        },
        {
            serviceName: "Appsync",
            cost: 0.000026784896851317373,
        },
    ];

    /**
     * 1 ユーザー当りの各 AWS サービスの利用料金
     */
    readonly AWS_COSTS_PER_USER: readonly AWSCost[] = [
        {
            serviceName: "Route53",
            cost: 0.027308909094649586,
        },
    ];

    /**
     * 1 テナント当たりの各 AWS サービスの利用料金
     */
    readonly AWS_COSTS_PER_TENANT: readonly AWSCost[] = [
        {
            serviceName: "CloudTrail",
            cost: 150,
        },
        {
            serviceName: "その他",
            cost: 20,
        },
    ];

    /**
     * 1 デバイス当たりの各 AWS サービスの利用料金
     */
    readonly AWS_COSTS_PER_DEVICE: readonly AWSCost[] = [
        {
            serviceName: "IoTCore::Connectivity",
            /**
             * 接続時間 (分) x 0.000000096 USD
             */
            cost: 0.000000096,
        },
    ];

    /**
     * 1 送信周期当たりの各 AWS サービスの利用料金
     */
    readonly AWS_COSTS_PER_SEND_CYCLE: readonly AWSCost[] = [
        {
            serviceName: "IoTCore::Messaging",
            /**
             * 1 送信周期当たりの請求可能なメッセージ数 x 0.0000012 USD = コスト
             */
            cost: this.IOT_CORE_BILLABLE_MESSAGES_PER_SEND_CYCLE * 0.0000012,
        },
        {
            serviceName: "IoTCore::RulesEngine::Rules",
            /**
             * 1 送信周期当たりのルール実行回数 x 請求可能なメッセージ数 x 0.00000018 USD = コスト
             */
            cost:
                this.IOT_CORE_RULES_PER_SEND_CYCLE *
                this.IOT_CORE_BILLABLE_MESSAGES_PER_SEND_CYCLE *
                0.00000018,
        },
        {
            serviceName: "IoTCore::RulesEngine::Actions",
            /**
             * 1 送信周期当たりのアクション実行回数 x 請求可能なメッセージ数 = 請求対象アクションの合計
             * - 1 送信周期当たりのアクション実行回数 = 1 送信周期当たりのルール実行回数 x 1 ルール当りのアクション数
             */
            cost:
                this.IOT_CORE_RULES_PER_SEND_CYCLE *
                this.IOT_CORE_ACTIONS_PER_RULE *
                this.IOT_CORE_BILLABLE_MESSAGES_PER_SEND_CYCLE *
                0.00000018,
        },
        {
            serviceName: "Lambda::Compute",
            /**
             * 1 送信周期当たりの実行回数 x 処理時間 x 0.0000166667 USD = コスト
             */
            cost:
                this.LAMBDA_INVOKES_PER_SEND_CYCLE *
                this.LAMBDA_DURATION *
                0.0000166667,
        },
        {
            serviceName: "Lambda::Request",
            /**
             * 1 送信周期当たりの実行回数 x 0.0000002 USD = コスト
             */
            cost: this.LAMBDA_INVOKES_PER_SEND_CYCLE * 0.0000002,
        },
    ];

    /**
     * データ項目 1 点当たりの各 AWS サービスの利用料金
     */
    readonly AWS_COSTS_PER_DATA_POINT: readonly AWSCost[] = [
        {
            serviceName: "DynamoDB::Write",
            /**
             * 1 点当たりの書き込み回数 * 1 点当たりの WRU (1) * 0.0000014269 USD = コスト
             */
            cost: this.DYNAMO_DB_WRITES_PER_DATA_POINT * 0.0000014269,
        },
        {
            serviceName: "DynamoDB::Read",
            /**
             * 1 点当たりの読込回数 * 1 点当たりの RRU (0.5) * 0.0000014269 USD = コスト
             */
            cost: this.DYNAMO_DB_READS_PER_DATA_POINT * 0.5 * 0.000000285,
        },
        {
            serviceName: "AppSync",
            /**
             * 1 点当たりの GraphQL 実行回数 * 0.000004 USD = コスト
             */
            cost: this.APP_SYNC_GQL_INVOKES_PER_DATA_POINT * 0.000004,
        },
    ];

    /**
     * 1 日当たりのイベントデータ数
     */
    readonly EVENT_COUNTS_PER_DATE: number = 30;

    /**
     * 1 PV 当りのコスト ( 各サービスの画面閲覧料金の合計 )
     */
    readonly costPerView: number;

    /**
     * 1 ユーザー 当りのコスト ( システム保守料金の合計 )
     */
    readonly costPerUser: number;

    /**
     * 今日の日にち
     */
    readonly todaysDate: number;

    /**
     * 今月の日数
     */
    readonly currentMonthDays: number;

    /**
     * 今月の分数
     */
    readonly currentMonthMinutes: number;

    /**
     * 今月の秒数
     */
    readonly currentMonthSeconds: number;

    /**
     * 今月の残日数
     */
    readonly currentMonthRemainingDays: number;

    /**
     * コンストラクタ
     */
    constructor() {
        // 1 PV 当りのコストを算出
        this.costPerView = this.AWS_COSTS_PER_VIEW.reduce(
            (a, b) => a + b.cost,
            0,
        );

        // 1 ユーザー当りのコストを算出
        this.costPerUser = this.AWS_COSTS_PER_USER.reduce(
            (a, b) => a + b.cost,
            0,
        );

        // 日付の計算
        const date = new Date();
        const currentMonthLastDate = new Date(
            date.getFullYear(),
            date.getMonth() + 1,
            0,
        ).getDate();
        this.todaysDate = date.getDate();
        this.currentMonthRemainingDays = currentMonthLastDate - this.todaysDate;
        this.currentMonthDays = currentMonthLastDate;
        this.currentMonthMinutes = 60 * 24 * this.currentMonthDays;
        this.currentMonthSeconds = this.currentMonthMinutes * 60;
    }

    /**
     * ページ閲覧回数によって利用料金を算出する
     * @param pageView ページ閲覧回数
     * @returns 利用料金
     */
    getCostByPV(pageView: number): number {
        return (
            (this.costPerView * pageView + (pageView && this.costPerUser)) *
            this.EXCHANGE_RATE
        );
    }

    /**
     * ページ閲覧回数によって当月の予測利用料金を算出する
     * @param pageView ページ閲覧回数
     * @returns 当月の予測利用料金
     */
    getForecastCostByPV(pageView: number): number {
        const pvPerDay = pageView / this.todaysDate;
        const forecastPV = pvPerDay * this.currentMonthRemainingDays;
        return this.getCostByPV(pageView + forecastPV);
    }

    /**
     * デバイス情報によって利用料金を算出する
     * @returns 当月の利用料
     */
    getCostsByDevice(device: IoTDevice): AWSCost[] {
        // デバイスの接続時間（分）を計算
        const connectTimeMinutes =
            this.currentMonthMinutes / Math.ceil(device.sendCycle / 60);

        // デバイスによる月間コストを計算
        const costByDevice = this.AWS_COSTS_PER_DEVICE.map((cost) => ({
            ...cost,
            cost: cost.cost * connectTimeMinutes,
        }));

        // 1 か月当りのデータ送信回数を計算
        const dataSendCount =
            this.currentMonthSeconds / device.sendCycle + // 定周期
            this.EVENT_COUNTS_PER_DATE * this.currentMonthDays; // イベント

        // 送信周期よる月間コストを計算
        const costBySendCycle = this.AWS_COSTS_PER_SEND_CYCLE.map((cost) => ({
            ...cost,
            cost: cost.cost * dataSendCount,
        }));

        // 1 か月当りの処理データ点数を計算
        const dataProcCount = dataSendCount * device.dataPoints;

        // データ点数による月間コストを計算
        const costByDataPoints = this.AWS_COSTS_PER_DATA_POINT.map((cost) => ({
            ...cost,
            cost: cost.cost * dataProcCount,
        }));

        // デバイスを設定してから現在までの経過時間（分）を計算
        const minutes = device.deployedAt
            ? Math.floor(
                  (Date.now() - Date.parse(device.deployedAt)) / (1000 * 60),
              )
            : 0;
        const oldDatumSizeByte = 200;
        const dynamoDBStorageGB =
            (minutes * device.dataPoints * oldDatumSizeByte * 5.5) /
                (1024 * 1024 * 1024) -
            25;

        const dynamoDBStorageCost =
            dynamoDBStorageGB > 0 ? dynamoDBStorageGB * 0.285 : 0;

        return [
            ...costByDevice,
            ...costBySendCycle,
            ...costByDataPoints,
            {
                serviceName: "DynamoDB::Storage",
                cost: dynamoDBStorageCost,
            },
        ];
    }
}
